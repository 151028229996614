import { Box, Button, Container, Stack, Typography } from '@mui/material';
import type { EventSeries, PaginatedList, Person } from 'core';
import type { FC } from 'react';
import { ErrorView } from '../../components/common/error.js';
import { ListPendingView } from '../../components/common/list-pending.js';
import { PageTitle } from '../../components/page-title/page-title.js';
import { useBoolFlag } from '../../hooks/use-flag.js';
import type { UsePromiseResult } from '../../utils/promise.js';
import { ListItemsView } from './list-items.js';
import { NoItemsView } from './no-items.js';

export interface PageProps {
  eventSeries: UsePromiseResult<PaginatedList<EventSeries.Type>>;
  person: Person.Type;
}

export const EventSeriesListPageView: FC<PageProps> = ({
  eventSeries: [data, error, state],
  person,
}) => (
  <PageTitle title="Event Series">
    <Container maxWidth="md" sx={{ my: 3 }}>
      <Stack spacing={3}>
        <Box display="flex" flex={1} justifyContent="space-between">
          <Typography variant="h2">My Series</Typography>
          {useBoolFlag('event-series.create') ? (
            <Box display="flex" justifyContent="end">
              <Button variant="outlined" href="#/event-series/create">
                Create Series
              </Button>
            </Box>
          ) : undefined}
        </Box>
        {state === 'pending' ? (
          <ListPendingView />
        ) : state === 'rejected' ? (
          <ErrorView error={error} />
        ) : !data || !data.matches ? (
          <NoItemsView />
        ) : (
          <ListItemsView data={data} person={person} />
        )}
      </Stack>
    </Container>
  </PageTitle>
);
