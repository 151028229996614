import { Stack, Typography } from '@mui/material';
import type { FC } from 'react';

export const NoItemsView: FC = () => (
  <Stack spacing={2}>
    <Typography variant="body2" color="text.secondary" fontStyle="italic">
      No results could be found.
    </Typography>
  </Stack>
);
