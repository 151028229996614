import { Key as MineIcon } from '@mui/icons-material';
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import type { EventSeries, PaginatedList, Person } from 'core';
import type { FC } from 'react';
import uri from 'uri-tag';
import { NotPublicChip } from '../../components/chips/not-public.js';
import { NotPublishedChip } from '../../components/chips/not-published.js';
import { GroupEntity } from '../../components/entity/group.js';
import { seriesSlugGroup } from '../../utils/url.js';

interface Props {
  data: PaginatedList<EventSeries.Type>;
  person: Person.Type;
}

export const ListItemsView: FC<Props> = ({
  data: { items, matches },
  person,
}) => (
  <Stack spacing={3}>
    {items
      .filter((item) => item?.country)
      .map((item) => (
        <ButtonBase
          key={item._id}
          sx={{ justifyContent: 'stretch', textAlign: 'start' }}
          href={uri`#/series/${seriesSlugGroup(item)}/${item.slug}`}
        >
          <Card sx={{ width: '100%' }}>
            <CardContent>
              <Stack spacing={1}>
                <Box display="flex" flex={1} justifyContent="space-between">
                  <Stack direction="row" spacing={1}>
                    <Typography variant="h3">{item.title}</Typography>
                    {!item.public ? (
                      item.groupIds ? (
                        item.groupIds.map((groupId) => (
                          <GroupEntity
                            key={groupId}
                            groupId={groupId}
                            link={true}
                          />
                        ))
                      ) : (
                        <NotPublicChip />
                      )
                    ) : undefined}
                    {!item.published ? <NotPublishedChip /> : undefined}
                  </Stack>
                  <Stack spacing={1} direction="row">
                    {item.admin?.personIds?.includes(person._id) && (
                      <MineIcon />
                    )}
                  </Stack>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </ButtonBase>
      ))}
  </Stack>
);
