import axios from 'axios';
import type { EventSeries, PaginatedList } from 'core';
import type { FC } from 'react';
import usePromise from 'react-use-promise';
import uri from 'uri-tag';
import { useAuthenticated } from '../../auth/auth-provider.js';
import { EventSeriesListPageView } from './view.js';

const Controller: FC = () => {
  const { person } = useAuthenticated();

  const eventSeries = usePromise(async () => {
    const { data } = await axios.get<PaginatedList<EventSeries.Type>>(
      uri`/api/v1/event-series`,
      {
        params: { sort: '-_id' }, // TODO
      },
    );
    return data;
  }, []);

  return EventSeriesListPageView({
    eventSeries,
    person,
  });
};

export default Controller;
